<template>
  <div>
    <header class="header_area">
      <nav class="navbar navbar-expand-lg navbar-light" style="background: #347dbe">
        <div class="container">
          <!-- Brand and toggle get grouped for better mobile display -->
          <router-link to="/" class="navbar-brand" style="color: white"><strong>EDUARDO NUNES</strong></router-link>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="fa fa-bars" style="color: white"></span>
          </button>
          <!-- Collect the nav links, forms, and other content for toggling -->
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="nav navbar-nav menu_nav ml-auto text-center">
              <li class="nav-item item-menu">
                <a href="#resumo" class="nav-link">RESUMO</a>
              </li>
              <li class="nav-item item-menu">
                <a href="#contato" class="nav-link">CONTATO</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>

    <section>
      <div>
        <div class="container">
          <div class="banner_inner">
            <div class="row">
              <div class="col-12 col-md-6" style="display: flex; justify-content: center">
                <div class="img-home"></div>
              </div>
              <div class="col-12 col-md-6 description-home">
                <div>
                  <div class="area-description">
                    <h6>Olá, eu sou o</h6>
                    <h2 style="font-weight: bold">Eduardo Nunes</h2>
                    <h6 style="font-weight: bold">Desenvolvedor FullStack</h6>
                    <p>Sempre gostei de criar soluções por mais simples que fossem. E quando descobri essa vasta área de desenvolvimento web,
                      empreguei todo meu tempo e dedicação em aprender novas tecnologias. E atualmente sou Desenvolvedor Web</p>
                    <ul>
                      <li><i class="fa fa-phone"></i> (92) 999537-5239</li>
                      <li><i class="fa fa-envelope"></i> nunes.eduardo1993@gmail.com</li>
                      <li><i class="fa fa-home"></i> Manaus-AM</li>
                    </ul>
                  </div>
                  <div class="area-description-social">
                    <ul>
                      <li><a href="https://www.facebook.com/eduardo.nunes.7165331" target="_blank"><i class="fa fa-facebook-official"></i></a></li>
                      <li><a href="https://www.instagram.com/enunes_lima/?hl=pt-br" target="_blank"><i class="fa fa-instagram"></i></a></li>
                      <li><a href="https://github.com/Endl13593" target="_blank"><i class="fa fa-github"></i></a></li>
                      <li><a href="https://www.linkedin.com/in/eduardo-nunes-0b541915b" target="_blank"><i class="fa fa-linkedin"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div id="resumo" style="margin-top: 50px">
        <div class="container" style="margin-bottom: 20px">
          <div class="row welcome_inner">
            <div class="col-lg-12">
              <div class="text-center">
                <h3 style="font-weight: bold; margin-top: 10px">Resumo</h3>
              </div>
              <hr>
              <div class="tools_expert">
                <div class="skill_main">
                  <div class="skill_item mb-4">
                    <h5>HTML</h5>
                    <div class="progress_br">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" style=" width: 99%" aria-valuenow="99" aria-valuemin="0" aria-valuemax="100"><strong>99%</strong></div>
                      </div>
                    </div>
                  </div>
                  <div class="skill_item mb-4">
                    <h5>CSS</h5>
                    <div class="progress_br">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" style=" width: 90%" aria-valuenow="90" aria-valuemax="100"><strong>90%</strong></div>
                      </div>
                    </div>
                  </div>
                  <div class="skill_item mb-4">
                    <h5>Javascript</h5>
                    <div class="progress_br">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" style=" width: 85%" aria-valuenow="85" aria-valuemax="100"><strong>85%</strong></div>
                      </div>
                    </div>
                  </div>
                  <div class="skill_item mb-4">
                    <h5>PHP</h5>
                    <div class="progress_br">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" style=" width: 90%" aria-valuenow="90" aria-valuemax="100"><strong>90%</strong></div>
                      </div>
                    </div>
                  </div>
                  <div class="skill_item mb-4">
                    <h5>Laravel</h5>
                    <div class="progress_br">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" style=" width: 85%" aria-valuenow="85" aria-valuemax="100"><strong>85%</strong></div>
                      </div>
                    </div>
                  </div>
                  <div class="skill_item mb-4">
                    <h5>Vue JS</h5>
                    <div class="progress_br">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" style=" width: 85%" aria-valuenow="85" aria-valuemax="100"><strong>85%</strong></div>
                      </div>
                    </div>
                  </div>
                  <div class="skill_item mb-4">
                    <h5>Android</h5>
                    <div class="progress_br">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" style=" width: 65%" aria-valuenow="65" aria-valuemax="100"><strong>65%</strong></div>
                      </div>
                    </div>
                  </div>
                  <div class="skill_item mb-4">
                    <h5>Docker</h5>
                    <div class="progress_br">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" style=" width: 80%" aria-valuenow="80" aria-valuemax="100"><strong>80%</strong></div>
                      </div>
                    </div>
                  </div>
                  <div class="skill_item mb-4">
                    <h5>Git</h5>
                    <div class="progress_br">
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" style=" width: 90%" aria-valuenow="90" aria-valuemax="100"><strong>90%</strong></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="background: #347dbe; width: 100%;">
          <div class="tabs_inner container">
            <ul class="nav nav-tabs tab-header" id="myTab" role="tablist">
              <li class="nav-item tab-item" style="margin-right: 10px">
                <a class="nav-link active tab-item" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Experiência</a>
              </li>
              <li class="nav-item tab-item">
                <a class="nav-link tab-item" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Formação Acadêmica</a>
              </li>
            </ul>
            <div class="tab-content resume-area">
              <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="main-timeline">
                        <div class="timeline">
                          <a href="#" class="timeline-content">
                            <div class="timeline-year">DEZ - 2018 / Atual</div>
                            <div class="timeline-icon"><i class="fa fa-arrows-h"></i></div>
                            <div class="inner-content">
                              <h3 class="title">Afline Mulserviços</h3>
                              <p class="description">
                                Desenvolvedor FullStack - Laravel, VueJS, Android
                              </p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade show" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="main-timeline">
                        <div class="timeline">
                          <a href="#" class="timeline-content">
                            <div class="timeline-year">SET - 2018</div>
                            <div class="timeline-icon"><i class="fa fa-arrows-h"></i></div>
                            <div class="inner-content">
                              <h3 class="title">Faculdade Martha falcão - Wyden</h3>
                              <p class="description">
                                Análise e Desenvolvimento de Sistemas - Manaus, AM
                              </p>
                            </div>
                          </a>
                        </div>
                        <div class="timeline">
                          <a href="#" class="timeline-content">
                            <div class="inner-content">
                              <h3 class="title">CETAM - AM</h3>
                              <p class="description">
                                Curso Técnico em Programação WEB
                              </p>
                            </div>
                            <div class="timeline-icon"><i class="fa fa-arrows-h"></i></div>
                            <div class="timeline-year">DEZ - 2012</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div id="contato" style="margin-top: 50px; margin-bottom: 20px">
        <div class="container">
          <div class="text-center">
            <h3 style="font-weight: bold; margin-top: 10px">Contato</h3>
          </div>
          <hr>
          <div class="row">
            <div class="col-12 col-md-6 col-sm-6 link-area">
              <a href="https://api.whatsapp.com/send?phone=5592995375239" target="_blank">
                <div class="card-custom">
                  <div class="icon-header">
                    <i class="fa fa-whatsapp"></i>
                  </div>
                  <div class="area-body">
                    <h5>(92) 9 9537-5239</h5>
                  </div>
                </div>
              </a>
            </div>

            <div class="col-12 col-md-6 col-sm-6">
              <div class="card-custom">
                <div class="icon-header">
                  <i class="fa fa-envelope"></i>
                </div>
                <div class="area-body">
                  <h5>nunes.eduardo1993@gmail.com</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6 col-sm-6 link-area">
              <a href="https://github.com/Endl13593">
                <div class="card-custom">
                  <div class="icon-header">
                    <i class="fa fa-github"></i>
                  </div>
                  <div class="area-body">
                    <h5>Endl13593</h5>
                  </div>
                </div>
              </a>
            </div>

            <div class="col-12 col-md-6 col-sm-6 link-area">
              <a href="https://www.linkedin.com/in/eduardo-nunes-0b541915b">
                <div class="card-custom">
                  <div class="icon-header">
                    <i class="fa fa-linkedin"></i>
                  </div>
                  <div class="area-body">
                    <h5>eduardo-nunes-0b541915b</h5>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6 col-sm-6 link-area">
              <a href="https://www.facebook.com/eduardo.nunes.7165331">
                <div class="card-custom">
                  <div class="icon-header">
                    <i class="fa fa-facebook-official"></i>
                  </div>
                  <div class="area-body">
                    <h5>eduardo.nunes.7165331</h5>
                  </div>
                </div>
              </a>
            </div>

            <div class="col-12 col-md-6 col-sm-6 link-area">
              <a href="https://www.instagram.com/enunes_lima/?hl=pt-br">
                <div class="card-custom">
                  <div class="icon-header">
                    <i class="fa fa-instagram"></i>
                  </div>
                  <div class="area-body">
                    <h5>@enunes_lima</h5>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <button id="btnTop" @click.prevent="backToTop"><i class="fa fa-arrow-circle-up"></i></button>
  </div>
</template>

<script>

export default {
  name: 'Home',
  methods: {
    scroller() {
      let button = document.getElementById('btnTop')
      if (document.documentElement.scrollTop > 70) {
        button.style.display = "block"
      } else {
        button.style.display = "none"
      }
    },
    backToTop() {
      document.documentElement.scrollTop = 0;
      this.$router.push('/')
    }
  },
  mounted() {
    let vm = this
    window.onscroll = () => {
      vm.scroller()
    }
    // eslint-disable-next-line no-undef
    $('.nav-link').click(function (){
      // eslint-disable-next-line no-undef
      $('#navbarSupportedContent').removeClass('show')
    })
  }
}
</script>
